/* Global styles */
html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  outline: none;
  border: none;
}


/* Import Angular Material Theming */
// @import '~@angular/material/theming';
// @include mat-core();

// /* Define your custom colors */
// $primary: mat-palette($mat-blue);
// $accent: mat-palette($mat-amber, A200, A100, A400);
// $warn: mat-palette($mat-red);

// /* Apply the theme */
// $theme: mat-light-theme($primary, $accent, $warn);
// @include angular-material-theme($theme);

/* Custom Angular Material Overrides */
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #673ab7 !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background-color: #673ab7 !important;
}

/* Global scrollbar styling */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* CKEditor Grid Overrides */
:root {
  --ck-color-grid-tile-size: 24px !important;
  --ck-color-color-grid-check-icon: #673ab7 !important;
}

/* Mat-Form Field Overrides */
:host ::ng-deep .mat-form-field-flex {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 39px !important;
}

:host ::ng-deep .mat-form-field-infix {
  display: flex;
  align-items: center;
  height: 100%;
}

:host ::ng-deep input {
  height: 39px !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Mat-Calendar Styling */
:host ::ng-deep .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: transparent !important;
  padding: 4px !important;
  // position: absolute !important;
}

// :host ::ng-deep .mat-calendar-body-cell-content.mat-focus-indicator {
//   position: static !important;

// }

// :host ::ng-deep .mat-calendar-body-cell-content {
//   top: initial !important;
//   left: initial !important;
//   z-index: initial !important;
//   display: initial !important;
//   align-items: initial !important;
//   justify-content: initial !important;
//   box-sizing: content-box !important;
//   width: auto !important;
//   height: auto !important;
//   line-height: normal !important;
//   border-width: 0 !important;
//   border-style: none !important;
//   border-radius: 0 !important;
// }

/* Calendar Table Adjustments */
:host ::ng-deep .mat-calendar-table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  height: 50px !important; /* Adjust height as needed */
}

:host ::ng-deep .mat-calendar-body-cell {
  height: 30px !important; /* Adjust cell height */
  padding: 5px !important; /* Adjust padding if necessary */
  vertical-align: middle !important; /* Adjust vertical alignment */
}


// :host ::ng-deep .mat-calendar-body-cell-content.mat-focus-indicator {
//   position: absolute !important; /* Override to fix the issue */
// }

.mat-focus-indicator {
  position: relative !important;
}


.mat-calendar-body-cell-content.mat-focus-indicator {
  position: absolute;
  width: 40px; /* Increase width of the circle */
  height: 40px; /* Increase height of the circle */
  border-width: 2px; /* Increase border thickness */
  line-height: 40px; /* Adjust line height to center the content */
  border-radius: 50%; /* Ensure it remains circular */
  padding: none;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, .38);
  width: 40px; /* Increase width of the circle */
  height: 40px; /* Increase height of the circle */
  border-width: 2px; /* Increase border thickness */
  line-height: 40px; /* Adjust line height to center the content */
  border-radius: 50%; /* Ensure it remains circular */
}
 td, th {
  padding: 0px !important; /* Remove padding */
  margin: 2px;
  height: 60px; /* Set the desired height for the table cell */
  line-height: 40px; /* Vertically center the content */
  text-align: center; /* Horizontally center the content */
  box-sizing: border-box; /* Include padding and borders in the height */
}
// .mat-calendar-body-label {
//   height: 0;
//   line-height: 0;
//   text-align: left;
//   padding-left: 4.7142857143%;
//   padding-right: 4.7142857143%;
//   display: inline-table;
// }

// th.mat-header-cell {
//   text-align: center !important;
//   margin: 3px !important;
// }


.mat-form-field{
  /* font-size: 14px; */
  width: 30%;
  line-height: 16px;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 6px .75em 0 .75em;
  margin-top: -0.25em;
  position: relative;
}


.mat-form-field-infix {
  // padding: .5em 0;
  border-top: 3px solid transparent; 
}

.mat-form-field-flex {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 39px !important;
  // padding-top: 7px !important;
}

// .mat-form-field-wrapper {
//   padding-bottom: 0 !important;
// }

th.mat-header-cell {
   text-align: center !important; 
}